import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDK } from "@sdk";
import { SocketConnector } from "@sdk/@libs/socket-connector";
import { iWAPrivateConnectionData } from "@sdk/conversations/conversations.models";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { WAState } from "@sdk/whatsapp-private/whatsapp-private.service";
import { Button, Divider, Form, Modal, Space, Switch } from "antd";
import { HelpCenterPromptButton } from "components/common/help-center-legacy/embeddable-components/help-center-prompt-button";
import { ModalTitle } from "components/common/modal-title";
import { SectionHeader } from "components/common/section-header";

import { ConfigurationEditorMiniComponent } from "components/common/configuration-editor-mini";
import { GeneralConnectionConfig } from "components/modules/connections/common-connection-settings/general-connection-config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { loadConnectionById } from "store/modules/connections/connections.helpers";
import { selectConnectionById } from "store/modules/connections/connections.selectors";
import { selectIsAdvancedMode } from "store/modules/ui-state/ui-state.selectors";
import { useQueryWithStore } from "store/store.hooks";
import TroubleshootWhatsappConnection from "../troubleshoot-whatsapp-private-connection-modal/troubleshoot-whatsapp-private-connection.modal-registry";
import { QRCodeImage } from "./qr-code-image";
import { QRCodeUpdate } from "./whatsapp-qr-code-status";

function hexToBase64(str) {
  return btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" "),
    ),
  );
}

export const ConfigureWhatsappPrivateConnection = ({
  connectionId,
  hideHeader,
  showOnlyQR,
}: {
  connectionId: string;
  hideHeader?: boolean;
  showOnlyQR?: boolean;
}) => {
  const { state: connection, retry: reload } = useQueryWithStore(
    selectConnectionById(connectionId),
    loadConnectionById(connectionId)(),
    [connectionId],
    !connectionId,
  );

  const {
    data: status,
    reload: reloadStatus,
    isLoading: isStatusLoading,
  } = useSDK(
    (SDK) => SDK.whatsAppPrivateX.getConnectionStatus(connectionId),
    [connectionId],
    !connectionId,
  );

  const { doAction: dismissConnectionError } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX.dismissConnectionError(connectionId),
      // successMessage: "Conversations has been synced",
      failureMessage: "Something went wrong",
    }),
    [connectionId],
  );

  const { doAction: syncConversations, isProcessing: isSyncingConversations } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.whatsAppPrivateX.syncRecentConversations(connectionId),
        successMessage: "Conversations has been synced",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  const { doAction: rebootInstance, isProcessing: isRebooting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.whatsAppPrivateX.rebootInstance(connectionId),
        successMessage: "Instance is rebooting",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  const { doAction: resetInstance, isProcessing: isResetting } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () => SDK.whatsAppPrivateX.resetInstance(connectionId),
        successMessage: "Instance is resetting",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  const { doAction: logout, isProcessing: isLoggingOut } = useSDKActionWithDeps(
    () => ({
      action: (SDK) => () =>
        SDK.whatsAppPrivateX.logout(connectionId).then(() => {
          return reloadStatus();
        }),
      successMessage: "Successfully logged out",
      failureMessage: "Something went wrong",
    }),
    [connectionId, reloadStatus],
  );

  const { doAction: sendTakeOverSignal, isProcessing: isTakingOver } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.whatsAppPrivateX.takeOverSession(connectionId),
        successMessage: "Took over the session",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  const { doAction: removeInstance, isProcessing: isRemovingInstance } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.whatsAppPrivateX.removeInstance(connectionId),
        successMessage: "WA Instance has been destroyed",
        failureMessage: "Something went wrong",
      }),
      [connectionId],
    );

  useEffect(() => {
    const listener = (data: {
      name: string;
      data: { connectionId: string } & QRCodeUpdate;
    }) => {
      console.log("Received Special Socket Event", data);
      if (
        data.name === "WHATSAPP_CONNECTION_STATUS" &&
        data.data.connectionId === connectionId
      ) {
        console.log("Reload Status");
        reloadStatus();
      }
    };
    if (SocketConnector.socket) {
      SocketConnector.socket.on("SPECIAL_EVENTS", listener);
      return () => {
        SocketConnector.socket.off("SPECIAL_EVENTS", listener);
      };
    }
  }, [connectionId, reloadStatus]);

  const connectionData = connection.data as iWAPrivateConnectionData;

  const [showScreenshot, setShowScreenshot] = useState(true);

  const {
    data: screenshot,
    isLoading,
    error,
    reload: reloadDebug,
  } = useSDK(
    (SDK) => SDK.whatsAppPrivateX.getScreenshot(connectionId),
    [connectionId, showScreenshot, status?.lastQrCode],
    !showScreenshot,
  );

  const screenshotUrl = useMemo(() => {
    try {
      const srcValue = "data:image/png;base64," + screenshot.base64;
      return srcValue;
    } catch (e) {
      return "";
    }
  }, [screenshot]);

  const viewScreenshot = useCallback(() => {
    Modal.info({
      title: (
        <ModalTitle
          title={"WA Instance"}
          icon={<i className="ri-eye-line"></i>}
        />
      ),
      icon: false,
      content: (
        <div
          style={{
            width: 750,
            height: 600,
            overflow: "auto",
          }}
        >
          <img src={screenshotUrl} alt="" />
        </div>
      ),
      okText: "Close",
      width: 800,
    });
  }, [screenshotUrl]);

  const { changePanelState } = useModalPanels();

  useEffect(() => {
    if (status?.state === WAState.CONNECTED && connection?.metaData?.hasError) {
      dismissConnectionError();
    }
  }, [connection?.metaData?.hasError, dismissConnectionError, status?.state]);

  const isAdvancedMode = useSelector(selectIsAdvancedMode);

  return (
    <div className="animated fadeInLeftMin  flex flex-col w-full">
      {!hideHeader && (
        <>
          <SectionHeader
            title="Connected Whatsapp Account"
            icon={<i className="ri-whatsapp-line"></i>}
          />
          <Divider />
          {(connection?.data as iWAPrivateConnectionData).isInstanceDeleted && (
            <>
              <div className="action-buttons my-4 flex flex-row justify-end items-center">
                <Space>
                  <Button
                    icon={<i className="ri-shut-down-line"></i>}
                    type="link"
                    onClick={resetInstance}
                    loading={isResetting}
                  >
                    Activate Instance
                  </Button>
                </Space>
              </div>
            </>
          )}
          {!(connection?.data as iWAPrivateConnectionData)
            .isInstanceDeleted && (
            <>
              <div className="action-buttons my-4 flex flex-row justify-center items-center border border-gray-100 dark:border-gray-800 rounded-lg p-2 mb-8">
                <Space wrap={true}>
                  <Button
                    icon={<i className="ri-refresh-line"></i>}
                    type="text"
                    onClick={syncConversations}
                    loading={isSyncingConversations}
                    // disabled
                  >
                    Sync Conversations
                  </Button>
                  <Button
                    icon={<i className="ri-stethoscope-line"></i>}
                    type="text"
                    onClick={() => {
                      changePanelState(TroubleshootWhatsappConnection, true, {
                        connectionId,
                      });
                    }}
                  >
                    Auto Troubleshoot
                  </Button>
                  <Button
                    icon={<i className="ri-restart-line"></i>}
                    type="text"
                    onClick={rebootInstance}
                    loading={isRebooting}
                  >
                    Reboot Instance
                  </Button>
                  <Button
                    icon={<i className="ri-shut-down-line"></i>}
                    type="text"
                    onClick={resetInstance}
                    loading={isResetting}
                  >
                    Reset Instance
                  </Button>
                  <Button
                    icon={<i className="ri-logout-box-line"></i>}
                    type="text"
                    onClick={logout}
                    loading={isLoggingOut}
                  >
                    Logout
                  </Button>
                  {isAdvancedMode && (
                    <>
                      <Button
                        icon={<i className="ri-delete-bin-line"></i>}
                        type="link"
                        onClick={removeInstance}
                        loading={isRemovingInstance}
                      >
                        Remove Instance
                      </Button>
                    </>
                  )}
                </Space>
              </div>
            </>
          )}
        </>
      )}

      {showOnlyQR && (
        <div className="flex flex-col w-full justify-center align-middle">
          <div className="flex flex-row items-center justify-center">
            <Button
              type="link"
              icon={<i className="ri-refresh-line"></i>}
              loading={isStatusLoading}
              onClick={reloadStatus}
            >
              Refresh QR Code
            </Button>
          </div>
          {isStatusLoading && (
            <div
              className="flex flex-col justify-center items-center text-center"
              style={{ height: 300 }}
            >
              <LoadingOutlined style={{ fontSize: 24 }} spin className="mb-4" />
              We are retrieve to retrieve the QR code.
              <br /> This could take up to a minute
            </div>
          )}
          {!isStatusLoading && status?.state === WAState.CONNECTED && (
            <div className="flex flex-col justify-center items-center">
              <div
                className="flex flex-row justify-center items-center"
                style={{
                  width: 300,
                  height: 300,
                }}
              >
                Connected
              </div>
            </div>
          )}

          {!isStatusLoading && status?.state !== WAState.CONNECTED && (
            <div className="flex flex-col justify-center items-center">
              {status?.lastQrCode ? (
                <>
                  <div
                    className="cursor-pointer"
                    style={{
                      width: 300,
                      height: 300,
                      backgroundPosition: "91% 43%",
                      backgroundSize: "294%",
                      backgroundImage: `url(${screenshotUrl})`,
                    }}
                    onClick={viewScreenshot}
                  ></div>
                  <HelpCenterPromptButton
                    type="BANNER"
                    articleId="fc89e0-bbabb"
                    text="Having Trouble?"
                    className="mt-4"
                  />
                </>
              ) : (
                // <QRCode value={status?.lastQrCode} size={300} />
                <div
                  className="flex flex-row justify-center items-center"
                  style={{
                    width: 300,
                    height: 300,
                  }}
                >
                  <LoadingOutlined
                    style={{ fontSize: 24 }}
                    spin
                    className="mb-4"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {!showOnlyQR && (
        <>
          <div className="data-table w-full p-4">
            <table className="simple-data-table w-full">
              <tbody>
                <tr>
                  <td
                    className="text-gray-700 dark:text-gray-200  mode_transition"
                    onClick={viewScreenshot}
                  >
                    Status
                  </td>
                  <td className="font-bold">
                    <div className="flex flex-row items-center">
                      {status?.state}{" "}
                      <Button
                        type="link"
                        icon={<i className="ri-refresh-line"></i>}
                        loading={isStatusLoading}
                        onClick={reloadStatus}
                      >
                        Refresh Status
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-gray-700 dark:text-gray-200  mode_transition">
                    Last Connected Number
                  </td>
                  <td className="font-bold">
                    <div className="flex flex-row items-center">
                      {connectionData?.lastConnectedNumber || "Not Found"}
                    </div>
                  </td>
                </tr>
                {!isStatusLoading && status?.state !== WAState.CONNECTED && (
                  <tr>
                    <td className="text-gray-700 dark:text-gray-200  mode_transition">
                      Authentication QR Code
                    </td>

                    <td className="font-bold">
                      {status?.lastQrCode ? (
                        <>
                          {showScreenshot ? (
                            <>
                              <div className="flex flex-col justify-center items-center border border-gray-200 rounded-lg p-4 dark:border-gray-800">
                                <div className="">
                                  Scan the QR Code to Authenticate
                                </div>
                                <div
                                  className="cursor-pointer"
                                  style={{
                                    width: 300,
                                    height: 300,
                                    backgroundPosition: "52.5% 42%",
                                    backgroundSize: "294%",
                                    backgroundImage: `url(${screenshotUrl})`,
                                  }}
                                  onClick={viewScreenshot}
                                ></div>
                                {/* <img
                                src={screenshotUrl}
                                alt="WA QR"
                                style={{
                                  objectFit: "cover",
                                  width: 300,
                                  height: 300,
                                  objectPosition: "100% 10%",
                                }}
                              /> */}
                                <HelpCenterPromptButton
                                  type="BANNER"
                                  articleId="fc89e0-bbabb"
                                  text="Having Trouble?"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              {/* <QRCode alue={status?.lastQrCode} size={300}  /> */}
                              <QRCodeImage
                                value={status?.lastQrCode}
                                size={300}
                              />
                              <Button
                                type="link"
                                icon={<i className="ri-question-line"></i>}
                                onClick={() => setShowScreenshot(true)}
                              >
                                Unable to Scan and Authenticate?
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <span>Not Available</span>
                      )}
                    </td>
                  </tr>
                )}

                {status?.info && (
                  <>
                    <tr>
                      <td className="text-gray-700 dark:text-gray-200 mode_transition">
                        Phone ID
                      </td>
                      <td className="font-bold">{status.info?.wid.user}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 dark:text-gray-200 mode_transition">
                        Platform
                      </td>
                      <td className="font-bold">{status.info?.platform}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 dark:text-gray-200 mode_transition">
                        Push Name
                      </td>
                      <td className="font-bold">{status.info?.pushname}</td>
                    </tr>
                    <tr>
                      <td className="text-gray-700 dark:text-gray-200 mode_transition">
                        Device
                      </td>
                      <td className="font-bold">
                        {status.info?.phone?.wa_version}{" "}
                        {status.info?.phone?.os_version}{" "}
                        {status.info?.phone?.device_manufacturer}{" "}
                        {status.info?.phone?.device_model}{" "}
                        {status.info?.phone?.os_build_number}{" "}
                        {!status.info?.phone && <>Not Available</>}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <HelpCenterPromptButton
            type="BANNER"
            text={"Video Guide: Troubleshoot Whatsapp Private Connections 📺"}
            description=""
            icon="LEARN"
            articleId="fc89e0-4c654"
            className="my-4"
          />

          <GeneralConnectionConfig
            connectionId={connectionId}
            withoutHeader
            powerSettingsDefaultExpanded={true}
            additionalPowerSettings={
              <>
                <ConfigurationEditorMiniComponent
                  entityType="CONNECTION"
                  entityId={connectionId}
                  icon={"ri-mail-lock-line"}
                  title="Restrict Chat Initiation"
                  description="Enable/disable chat initiation from this connection"
                  miniEditor={
                    <>
                      <Form.Item
                        valuePropName="checked"
                        name={["data", "restrictChatInitiation"]}
                      >
                        <Switch size="small" />
                      </Form.Item>
                    </>
                  }
                ></ConfigurationEditorMiniComponent>
              </>
            }
          />
        </>
      )}
    </div>
  );
};
