import { axios, AxiosResponse } from "@sdk/axios";
import {
  PaginatedOptions,
  PaginatedResults,
} from "@sdk/utils/paginated-results";
import dayjs from "dayjs";
import { DeepPartial } from "redux";
import { UserTracker } from "user-tracker";
import { FbPostCommentFilters } from "./facebook-posts.controller-models";
import { iFbPost, iFbPostComment } from "./facebook-posts.models";

// tslint:disable-next-line:max-line-length

export class FbPostsService {
  constructor(protected config: { basePath: string; token: string }) {}

  async queryFbPosts(req: { query: any; options: PaginatedOptions }) {
    req.query["metaData.createdTime"] = {
      $lte: Date.now(),
    };
    const results: AxiosResponse<PaginatedResults<iFbPost>> = await axios.post(
      this.config.basePath + `/fb-posts/query`,
      req,
      {
        headers: { Authorization: this.config.token },
      },
    );
    // * Patch to hide scheduled posts
    const originalResults = results.data.docs || [];

    results.data.docs = (results.data.docs || []).filter((item) =>
      dayjs(item.created_time).isBefore(dayjs()),
    );

    if (originalResults.length && results.data.docs.length === 0) {
      results.data.docs = originalResults.slice(0, 1);
    }
    return results.data;
  }

  async getFbPost(postId: string) {
    const results: AxiosResponse<iFbPost> = await axios.get(
      this.config.basePath + `/fb-posts/${postId}`,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async getFbPostComments(
    {
      postId,
      filter,
      searchQuery,
    }: { postId: string; filter: FbPostCommentFilters; searchQuery?: string },
    { limit = 100, offset = 0 }: PaginatedOptions,
  ) {
    // Todo: Need to add limit and page parameters
    const results: AxiosResponse<PaginatedResults<iFbPostComment>> =
      await axios.get(this.config.basePath + `/fb-posts/${postId}/comments`, {
        headers: { Authorization: this.config.token },
        params: {
          limit,
          offset,
          filter,
          searchQuery,
        },
      });
    return results.data;
  }

  async editFbPost(postId: string, edits: DeepPartial<iFbPost>) {
    const results: AxiosResponse<iFbPost> = await axios.patch(
      this.config.basePath + `/fb-posts/${postId}`,
      edits,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async deleteFbPost(postId: string) {
    const results: AxiosResponse<{ status: boolean }> = await axios.delete(
      this.config.basePath + `/fb-posts/${postId}`,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async syncAllPosts(connectionId: string, forceRefresh?: boolean) {
    UserTracker.track("FB_POSTS - Sync All Posts");
    const results: AxiosResponse<boolean> = await axios.post(
      this.config.basePath + `/fb-posts/sync`,
      { connectionId, forceRefresh },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  /*----------  Post Actions  ----------*/

  async reloadPost(id: string) {
    UserTracker.track("FB_POSTS - Reload Post");
    const results: AxiosResponse<{
      post: iFbPost;
      comments: iFbPostComment[];
    }> = await axios.post(
      this.config.basePath + `/fb-posts/${id}/reload`,
      {},
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async assignFbPost(postId: string, userId: string, status: boolean) {
    const results: AxiosResponse<iFbPost> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/assign`,
      { userId, status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async reactToPost(
    postId: string,
    req: { reaction: string; status: boolean },
  ) {
    UserTracker.track("FB_POSTS - React to Post");
    const results: AxiosResponse<iFbPost> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/react`,
      req,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async createComment(postId: string, comment: string, attachmentUrl?: string) {
    UserTracker.track("FB_POSTS - Add Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/comments`,
      { comment, attachmentUrl },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async archivePost(postId: string, status: boolean) {
    UserTracker.track("FB_POSTS - Archive Post");
    const results: AxiosResponse<iFbPost> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/archive`,
      { status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  /*----------  Post Comment Actions  ----------*/

  async getFbCommentById(postId: string, commentId: string) {
    const results: AxiosResponse<iFbPostComment> = await axios.get(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}`,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async reactToComment(
    postId: string,
    commentId: string,
    req: { reaction: string; status: boolean },
  ) {
    UserTracker.track("FB_POSTS - React to Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}/react`,
      req,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async deleteComment(
    postId: string,
    commentId: string,
    parentCommentId?: string,
  ) {
    UserTracker.track("FB_POSTS - Delete Comment");
    const results: AxiosResponse<{ success: boolean }> = await axios.delete(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}`,
      {
        headers: { Authorization: this.config.token },
        params: {
          parentCommentId,
        },
      },
    );
    return results.data;
  }

  async hideComment(
    postId: string,
    commentId: string,
    parentCommentId?: string,
  ) {
    UserTracker.track("FB_POSTS - Hide Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.patch(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}/hide`,
      { parentCommentId },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async unHideComment(
    postId: string,
    commentId: string,
    parentCommentId?: string,
  ) {
    UserTracker.track("FB_POSTS - Unhide Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.patch(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}/hide`,
      { unHide: true, parentCommentId },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async replyToComment(
    postId: string,
    commentId: string,
    comment: string,
    attachmentUrl?: string,
  ) {
    UserTracker.track("FB_POSTS - Reply to Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}/reply`,
      { comment, attachmentUrl },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async markAsResolved(id: string, commentId: string, status: boolean) {
    UserTracker.track("FB_POSTS - Mark Comment as Resolved");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${id}/comments/${commentId}/resolve`,
      { status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async markAsAllResolved(id: string, status: boolean) {
    UserTracker.track("FB_POSTS - Mark All Comments as Resolved");
    const results: AxiosResponse<boolean> = await axios.post(
      this.config.basePath + `/fb-posts/${id}/resolve-all`,
      { status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async lockComment(id: string, commentId: string, status: boolean) {
    UserTracker.track("FB_POSTS - Lock Comment", {
      status,
    });
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${id}/comments/${commentId}/lock`,
      { status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async privateReply(
    postId: string,
    commentId: string,
    reply: string,
    parentCommentId?: string,
  ) {
    UserTracker.track("FB_POSTS - Make Private Reply");
    const results: AxiosResponse<{ status: boolean }> = await axios.post(
      this.config.basePath +
        `/fb-posts/${postId}/comments/${commentId}/private-reply`,
      { reply, parentCommentId },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async assignUserToComment(
    postId: string,
    commentId: string,
    userId: string,
    status: boolean,
  ) {
    UserTracker.track("FB_POSTS - Assign User to Comment");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath + `/fb-posts/${postId}/comments/${commentId}/assign`,
      { userId, status },
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  /*----------  Sub Comment Actions  ----------*/

  async reactToCommentReply(
    postId: string,
    commentId: string,
    subCommentId: string,
    req: { reaction: string; status: boolean },
  ) {
    UserTracker.track("FB_POSTS - React to comment Reply");
    const results: AxiosResponse<iFbPostComment> = await axios.post(
      this.config.basePath +
        `/fb-posts/${postId}/comments/${commentId}/comments/${subCommentId}/react`,
      req,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }

  async deleteCommentReply(
    postId: string,
    commentId: string,
    subCommentId: string,
  ) {
    UserTracker.track("FB_POSTS - Delete Comment Reply");
    const results: AxiosResponse<iFbPostComment> = await axios.delete(
      this.config.basePath +
        `/fb-posts/${postId}/comments/${commentId}/comments/${subCommentId}`,
      {
        headers: { Authorization: this.config.token },
      },
    );
    return results.data;
  }
}
